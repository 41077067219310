import type { OrderState } from "@/model/order-state"
import { useHttpClient } from "@/vf"
import { defineStore } from "pinia"
import { ref } from "vue"

export interface GlobalState {
    newCustomers: number
    openOrders: number
    articlesVisibleWithoutStock: number
    ordersByState: Record<OrderState, number>
    ordersCompleteNotPaid: number
    doctorQuestionnairesToReview: number
}

export const useGlobalState = defineStore("global-state", () => {
    const data = ref<GlobalState>()
    const loading = ref<boolean>(false)
    const { getBg } = useHttpClient()

    async function refresh() {
        try {
            loading.value = true
            data.value = (await getBg<GlobalState>("/global-state")).data
        } catch {
            // ignore
        } finally {
            loading.value = false
        }
    }

    if (!data.value && !loading.value) {
        refresh()
    }
    setInterval(refresh, 120_000)

    return { data, refresh } as const
})
